@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Signika:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

body {
  margin: 0;
  padding:0;
  box-sizing: border-box;
  font-size: 11pt;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

body::-webkit-scrollbar {
  display: none;
}

#snap-midtrans{
  position: fixed !important;
}
iframe{
  position: unset !important;
}

/* *{
  font-family: 'Abril Fatface', cursive;
font-family: 'Signika', sans-serif;
} */

a{
  text-decoration: none;
  color:inherit;
}

a:hover{
  color:inherit;
}

button:focus-visible{
  border: 1.5px solid #2EBDED;
  box-shadow: 0px 0px 0px 4px rgba(100, 210, 247, 0.1);
}

button:focus{
outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:focus.btn{
  outline: none !important;
  box-shadow: none !important;
}

.form-control{
  border-radius:0 !important;
}

:focus.form-control{
  box-shadow: none !important;
}

/* zoommm ================= */

img[data-action="zoom"] {
	cursor: zoom-in;
}
.zoom-img,
.zoom-img-wrap {
	position: relative;
	z-index: 666;
	transition: all 300ms;
}
img.zoom-img {
	cursor: zoom-out;
}
.zoom-overlay {
	cursor: zoom-out;
	z-index: 420;
	background: #fff;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	filter: "alpha(opacity=0)";
	opacity: 0;
	transition: opacity 300ms;
}
.zoom-overlay-open .zoom-overlay {
	filter: "alpha(opacity=100)";
	opacity: 1;
}

/* zoommm ================= */


/* DISPLAY TAG ========== */
.tag{
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 4px;
  color: #4a4a4a;
  display: inline-flex;
  font-size: .875rem;
  height: 2em;
  justify-content: center;
  line-height: 1.5;
  padding-left: 0.75em;
  padding-right: 0.75em;
  white-space: break-spaces;
}

.tag.is-success{
  background-color: #2dbded;
  color: #fff;
  background: linear-gradient(270deg, #2dbded, #6897d0, #2e3894);
  background-size: 300% 100%;
  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
  padding:5px 7px;
  border-radius:5px;
}

.tag.is-danger{
  background-color: #ed2d5a;
  color: #fff;
}

.button.is-primary {
  background-color: #2dbded;
  border-color: transparent !important;
  color: #fff;
}


/* PAGINATION ==================== */

#pressRealesePagination .pagination a {
  display: flex;
  width: 30px;
  height: 30px;
  padding: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 4px !important;
  border: 1px solid #8898AA;
  color: #535861 !important;
  text-decoration: none;
  margin: 0px 2px;
  font-weight: 600;
}

#pressRealesePagination .pagination__link--active a {
  background-color: transparent !important;
  color: #3B3E98;
  border: 1px solid #3B3E98;
}

#pressRealesePagination .pagination__link--disabled a {
  color: #C8C9CC;
  border: 1px solid #83878D;
  background: #83878D;
}




/* GENERAL ============= */

.pagination {
  display: flex;
  padding: 0px 20px;
  list-style: none;
  cursor: pointer;
}

.pagination a {
  /* padding: 5px 8px; */
  display: flex;
  width: 30px;
  height: 30px;
  padding: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 100%;
  border: 1px solid #8898AA;
  color: #8898AA !important;
  text-decoration: none;
  margin: 0px 2px;
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  color: #fff !important;
  background: #6c7ac9;
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}




/* TABSSSSS================= */

.tabs{
  text-align: center;
  justify-content: center;
  align-items: center;
}

.tab-header {
  list-style: none;
  /* background: #fff; */
  /* border-radius: 36px; */
  /* line-height: 25px; */
  display: flex;
  padding: 0;
  /* justify-content: center;
  align-items: center; */
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
}
.tab-header li {
  display: flex;
  padding: 7px 12px;
  /* border-radius: 36px; */
  /* line-height: 35px; */
  /* background: red; */
  cursor: pointer;
  font-weight: 600;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  border:1px solid #ccc;
}

.tab-header li:first-child{
  border-radius: 36px 0px 0px 36px;
}
.tab-header li:last-child{
  border-radius: 0px 36px 36px 0px;
}

.tab-header li.active,
.tab-header li:hover {
  color:#fff;
  background: #4cb8c4; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #4cb8c4,
    #3cd3ad
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #4cb8c4,
    #3cd3ad
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}


/* TABSS BOOTSTRAP */

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #495057;
  background-color: transparent;
  border:none;
  border-bottom: 2px solid #4cb8c4;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: 0 0;
  border: none;
  font-weight:600;
  color:#495057;
  /* border-top-left-radius: .25rem;
  border-top-right-radius: .25rem; */
}

.table-fixed tbody {
  height: 300px;
  overflow-y: auto;
  width: 100%;
}

.table-fixed thead,
.table-fixed tbody,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
  display: block;
}

.table-fixed tbody td,
.table-fixed tbody th,
.table-fixed thead > tr > th {
  float: left;
  position: relative;

  /* ::after {
    content: '';
    clear: both;
    display: block;
  } */
}



.carousel-indicators .active {
    opacity: 1;
}


.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 10px;
  height: 10px;
  border-radius: 100px !important;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #3B3E98;
  background-clip: padding-box;
  border: 0;
  /* border-top: 10px solid transparent; */
  /* border-bottom: 10px solid transparent; */
  /* opacity: .5; */
  transition: opacity .6s ease;
}