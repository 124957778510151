

#container-tabs .nav-tabs {
  border-bottom: none !important;
  justify-content: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;

}

#container-tabs .nav-tabs .nav-link{
  background-color: #FFFFFF;
  width: 190px;
  height:60px;
  margin-bottom: -1px;
  position: relative;
  text-transform: uppercase;
  color:#3B3E98;
  
}

#container-tabs .nav-tabs .nav-link.active::after{
    bottom:-20px;
    right: 80px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #3B3E98;
    border-width: 10px;
    margin-left: -10px;
    transform: rotate(180deg);
}

 /* .nav-tabs  .nav-link:nth-child(1){
  border-radius: 6px 0px 0px 6px;

}
 .nav-tabs  .nav-link:nth-child(2){
  border-radius: 6px 6px 6px 6px !important;

} */


#container-tabs .nav-tabs .nav-link.active {

  background-color: #3B3E98;
  color: #FFFFFF;
  border-bottom: none !important;
}