
.button-secondary{
  height: 38px;
  overflow: hidden;

  /* Brand Color/Primary */

  background: #6f72b2;
  border-radius: 4px;
  /* CTA/Large - Bold */

  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 23px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  /* Color/Neutral 10 */

  color: #ffffff;

}

.button-primary{
  height: 38px;
  overflow: hidden;

  /* Brand Color/Primary */

  background: #3b3e98;
  border-radius: 4px;
  /* CTA/Large - Bold */

  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 23px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  /* Color/Neutral 10 */

  color: #ffffff;


}

.button-green{
  height: 38px;
  overflow: hidden;

  /* Brand Color/Primary */

  background: #78f244;
  border-radius: 4px;
  /* CTA/Large - Bold */

  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 23px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  /* Color/Neutral 10 */

  color: #ffffff;

}
.button-green:hover{
  color:#fff
}

.button-secondary:hover{
  color: #ffff;
}

.button-primary:hover{

  color: #ffff;
}


.button-auth-google{
  width: 100%;
  justify-content: center;
}