.slide-container{
  max-width: 1120px;
  width: 100%;
  padding: 40px 0;
}

.slide-container > .arrow-container{
  margin-top:58px;
}
.slide-content{
  margin: 0 40px;
  overflow: hidden;
  border-radius: 25px;
}
.card{
  border-radius: 25px;
}
.image-content,
.card-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 14px;
}
.image-content{
  position: relative;
  row-gap: 5px;
  padding: 25px 0;
}
.overlay{
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 25px 25px 0 25px;
}
.overlay::before,
.overlay::after{
  content: '';
  position: absolute;
  right: 0;
  bottom: -40px;
  height: 40px;
  width: 40px;
  background-color: #4070F4;
}
.overlay::after{
  border-radius: 0 25px 0 0;
  /* background-color: #FFF; */
}
.card-image{
  position: relative;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  /* background: #FFF; */
  padding: 3px;
}
.card-image .card-img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 4px solid #4070F4;
}
.name{
  font-size: 18px;
  font-weight: 500;
  color: #333;
}
.description{
  font-size: 14px;
  color: #707070;
  text-align: center;
}
.button{
  border: none;
  font-size: 16px;
  color: #FFF;
  padding: 8px 16px;
  background-color: #4070F4;
  border-radius: 6px;
  margin: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.button:hover{
  background: #265DF2;
}

.swiper-navBtn{
  /* color: #6E93f7; */
  transition: color 0.3s ease;
}
/* .swiper-navBtn:hover{
  color: #4070F4;
}
.swiper-navBtn::before,
.swiper-navBtn::after{
  font-size: 35px;
} */

.swiper-button-next {
  position: unset !important;
  /* top: 50%; */
  /* width: calc(var(--swiper-navigation-size)/ 44 * 27); */
  /* height: var(--swiper-navigation-size); */
  /* margin-top: calc(0px - (var(--swiper-navigation-size)/ 2)); */
  /* z-index: 10; */
  /* cursor: pointer; */
  /* display: flex; */
  align-items: center;
  justify-content: center;
  color: black;
}
.swiper-button-prev {
  
  position: unset !important;
  top: 50%;
  margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color,var(--swiper-theme-color));
}
.swiper-button-prev, .swiper-rtl .swiper-button-next {
  left: auto !important;
  right: auto;
}
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  content: '' !important;
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  content: '' !important;
}
.swiper-button-next:after, .swiper-button-prev:after {
  font-family: poppins !important;
  text-transform: none!important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
}
.swiper-button-next{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  width:53px !important;
  height: 53px !important;
  border-radius:100px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.swiper-button-prev img{
  
  transform: rotate(180deg);
}
.swiper-button-prev{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  width:53px !important;
  height: 53px !important;
  border-radius:100px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.swiper-button-prev1 > img{
  transform: rotate(180deg);
}
.swiper-pagination-bullet{
  background-color: #6E93f7;
  opacity: 1;
}
.swiper-pagination-bullet-active{
  background-color: #4070F4;
}

@media screen and (max-width: 768px) {
  .slide-content{
    margin: 0 10px;
  }
  .swiper-navBtn{
    display: none;
  }
}
