@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?4981291');
  src: url('../font/fontello.eot?4981291#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?4981291') format('woff2'),
       url('../font/fontello.woff?4981291') format('woff'),
       url('../font/fontello.ttf?4981291') format('truetype'),
       url('../font/fontello.svg?4981291#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?4981291#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-activity:before { content: '\e800'; } /* '' */
.icon-advertising:before { content: '\e801'; } /* '' */
.icon-airplay:before { content: '\e802'; } /* '' */
.icon-alert_circle:before { content: '\e803'; } /* '' */
.icon-alert_octagon:before { content: '\e804'; } /* '' */
.icon-alert_triangle:before { content: '\e805'; } /* '' */
.icon-align_center:before { content: '\e806'; } /* '' */
.icon-align-justify:before { content: '\e807'; } /* '' */
.icon-align-left:before { content: '\e808'; } /* '' */
.icon-align-right:before { content: '\e809'; } /* '' */
.icon-anchor:before { content: '\e80a'; } /* '' */
.icon-aperture:before { content: '\e80b'; } /* '' */
.icon-archive:before { content: '\e80c'; } /* '' */
.icon-arrow-down:before { content: '\e80d'; } /* '' */
.icon-arrow-down-circle:before { content: '\e80e'; } /* '' */
.icon-arrow-down-left:before { content: '\e80f'; } /* '' */
.icon-arrow-down-right:before { content: '\e810'; } /* '' */
.icon-arrow-left:before { content: '\e811'; } /* '' */
.icon-arrow-left-circle:before { content: '\e812'; } /* '' */
.icon-arrow-right:before { content: '\e813'; } /* '' */
.icon-arrow-right-circle:before { content: '\e814'; } /* '' */
.icon-arrow-up:before { content: '\e815'; } /* '' */
.icon-arrow-up-circle:before { content: '\e816'; } /* '' */
.icon-arrow-up-left:before { content: '\e817'; } /* '' */
.icon-arrow-up-right:before { content: '\e818'; } /* '' */
.icon-at-sign:before { content: '\e819'; } /* '' */
.icon-award:before { content: '\e81a'; } /* '' */
.icon-bar-chart:before { content: '\e81b'; } /* '' */
.icon-bar-chart-2:before { content: '\e81c'; } /* '' */
.icon-battery:before { content: '\e81d'; } /* '' */
.icon-battery-charging:before { content: '\e81e'; } /* '' */
.icon-bell:before { content: '\e81f'; } /* '' */
.icon-bell-off:before { content: '\e820'; } /* '' */
.icon-bluetooth:before { content: '\e821'; } /* '' */
.icon-bold:before { content: '\e822'; } /* '' */
.icon-book:before { content: '\e823'; } /* '' */
.icon-bookmark:before { content: '\e824'; } /* '' */
.icon-book-open:before { content: '\e825'; } /* '' */
.icon-box:before { content: '\e826'; } /* '' */
.icon-briefcase:before { content: '\e827'; } /* '' */
.icon-calendar:before { content: '\e828'; } /* '' */
.icon-camera:before { content: '\e829'; } /* '' */
.icon-camera-off:before { content: '\e82a'; } /* '' */
.icon-cast:before { content: '\e82b'; } /* '' */
.icon-check:before { content: '\e82c'; } /* '' */
.icon-check-circle:before { content: '\e82d'; } /* '' */
.icon-check-square:before { content: '\e82e'; } /* '' */
.icon-chevron-down:before { content: '\e82f'; } /* '' */
.icon-chevron-left:before { content: '\e830'; } /* '' */
.icon-chevron-right:before { content: '\e831'; } /* '' */
.icon-chevrons-down:before { content: '\e832'; } /* '' */
.icon-chevrons-left:before { content: '\e833'; } /* '' */
.icon-chevrons-right:before { content: '\e834'; } /* '' */
.icon-chevrons-up:before { content: '\e835'; } /* '' */
.icon-chevron-up:before { content: '\e836'; } /* '' */
.icon-chrome:before { content: '\e837'; } /* '' */
.icon-circle:before { content: '\e838'; } /* '' */
.icon-clipboard:before { content: '\e839'; } /* '' */
.icon-clock:before { content: '\e83a'; } /* '' */
.icon-cloud:before { content: '\e83b'; } /* '' */
.icon-cloud-lightning:before { content: '\e83c'; } /* '' */
.icon-cloud-drizzle:before { content: '\e83d'; } /* '' */
.icon-cloud-off:before { content: '\e83e'; } /* '' */
.icon-cloud-rain:before { content: '\e83f'; } /* '' */
.icon-cloud-snow:before { content: '\e840'; } /* '' */
.icon-code:before { content: '\e841'; } /* '' */
.icon-codepen:before { content: '\e842'; } /* '' */
.icon-codesandbox:before { content: '\e843'; } /* '' */
.icon-coffee:before { content: '\e844'; } /* '' */
.icon-columns:before { content: '\e845'; } /* '' */
.icon-command:before { content: '\e846'; } /* '' */
.icon-companies:before { content: '\e847'; } /* '' */
.icon-compass:before { content: '\e848'; } /* '' */
.icon-copy:before { content: '\e849'; } /* '' */
.icon-corner-down-left:before { content: '\e84a'; } /* '' */
.icon-corner-down-right:before { content: '\e84b'; } /* '' */
.icon-corner-left-down:before { content: '\e84c'; } /* '' */
.icon-corner-left-up:before { content: '\e84d'; } /* '' */
.icon-corner-right-down:before { content: '\e84e'; } /* '' */
.icon-corner-right-up:before { content: '\e84f'; } /* '' */
.icon-corner-up-left:before { content: '\e850'; } /* '' */
.icon-corner-up-right:before { content: '\e851'; } /* '' */
.icon-cpu:before { content: '\e852'; } /* '' */
.icon-create:before { content: '\e853'; } /* '' */
.icon-credit-card:before { content: '\e854'; } /* '' */
.icon-crop:before { content: '\e855'; } /* '' */
.icon-crosshair:before { content: '\e856'; } /* '' */
.icon-cs:before { content: '\e857'; } /* '' */
.icon-database:before { content: '\e858'; } /* '' */
.icon-delete:before { content: '\e859'; } /* '' */
.icon-disc:before { content: '\e85a'; } /* '' */
.icon-divide:before { content: '\e85b'; } /* '' */
.icon-divide-circle:before { content: '\e85c'; } /* '' */
.icon-divide-square:before { content: '\e85d'; } /* '' */
.icon-dollar-sign:before { content: '\e85e'; } /* '' */
.icon-download:before { content: '\e85f'; } /* '' */
.icon-download-cloud:before { content: '\e860'; } /* '' */
.icon-dribbble:before { content: '\e861'; } /* '' */
.icon-droplet:before { content: '\e862'; } /* '' */
.icon-edit:before { content: '\e863'; } /* '' */
.icon-edit-2:before { content: '\e864'; } /* '' */
.icon-edit-3:before { content: '\e865'; } /* '' */
.icon-event:before { content: '\e866'; } /* '' */
.icon-external-link:before { content: '\e867'; } /* '' */
.icon-eye:before { content: '\e868'; } /* '' */
.icon-eye-off:before { content: '\e869'; } /* '' */
.icon-facebook:before { content: '\e86a'; } /* '' */
.icon-fast-forward:before { content: '\e86b'; } /* '' */
.icon-feather:before { content: '\e86c'; } /* '' */
.icon-figma:before { content: '\e86d'; } /* '' */
.icon-file:before { content: '\e86e'; } /* '' */
.icon-file-minus:before { content: '\e86f'; } /* '' */
.icon-file-plus:before { content: '\e870'; } /* '' */
.icon-file-text:before { content: '\e871'; } /* '' */
.icon-film:before { content: '\e872'; } /* '' */
.icon-filter:before { content: '\e873'; } /* '' */
.icon-flag:before { content: '\e874'; } /* '' */
.icon-folder:before { content: '\e875'; } /* '' */
.icon-folder-minus:before { content: '\e876'; } /* '' */
.icon-folder-plus:before { content: '\e877'; } /* '' */
.icon-framer:before { content: '\e878'; } /* '' */
.icon-frown:before { content: '\e879'; } /* '' */
.icon-gift:before { content: '\e87a'; } /* '' */
.icon-git-branch:before { content: '\e87b'; } /* '' */
.icon-git-commit:before { content: '\e87c'; } /* '' */
.icon-github:before { content: '\e87d'; } /* '' */
.icon-gitlab:before { content: '\e87e'; } /* '' */
.icon-git-merge:before { content: '\e87f'; } /* '' */
.icon-git-pull-request:before { content: '\e880'; } /* '' */
.icon-globe:before { content: '\e881'; } /* '' */
.icon-grid:before { content: '\e882'; } /* '' */
.icon-hard-drive:before { content: '\e883'; } /* '' */
.icon-hash:before { content: '\e884'; } /* '' */
.icon-headphones:before { content: '\e885'; } /* '' */
.icon-heart:before { content: '\e886'; } /* '' */
.icon-help-circle:before { content: '\e887'; } /* '' */
.icon-hexagon:before { content: '\e888'; } /* '' */
.icon-home:before { content: '\e889'; } /* '' */
.icon-image:before { content: '\e88a'; } /* '' */
.icon-inbox:before { content: '\e88b'; } /* '' */
.icon-info:before { content: '\e88c'; } /* '' */
.icon-instagram:before { content: '\e88d'; } /* '' */
.icon-italic:before { content: '\e88e'; } /* '' */
.icon-key:before { content: '\e88f'; } /* '' */
.icon-layers:before { content: '\e890'; } /* '' */
.icon-layout:before { content: '\e891'; } /* '' */
.icon-life-buoy:before { content: '\e892'; } /* '' */
.icon-link:before { content: '\e893'; } /* '' */
.icon-link-2:before { content: '\e894'; } /* '' */
.icon-linkedin:before { content: '\e895'; } /* '' */
.icon-list:before { content: '\e896'; } /* '' */
.icon-loader:before { content: '\e897'; } /* '' */
.icon-lock:before { content: '\e898'; } /* '' */
.icon-log-in:before { content: '\e899'; } /* '' */
.icon-logogram_color:before { content: '\e89a'; } /* '' */
.icon-logogram_outline:before { content: '\e89b'; } /* '' */
.icon-log-out:before { content: '\e89c'; } /* '' */
.icon-mail:before { content: '\e89d'; } /* '' */
.icon-map:before { content: '\e89e'; } /* '' */
.icon-map-pin:before { content: '\e89f'; } /* '' */
.icon-maximize:before { content: '\e8a0'; } /* '' */
.icon-maximize-2:before { content: '\e8a1'; } /* '' */
.icon-meh:before { content: '\e8a2'; } /* '' */
.icon-menu:before { content: '\e8a3'; } /* '' */
.icon-message-circle:before { content: '\e8a4'; } /* '' */
.icon-message-square:before { content: '\e8a5'; } /* '' */
.icon-mic:before { content: '\e8a6'; } /* '' */
.icon-mic-off:before { content: '\e8a7'; } /* '' */
.icon-minimize:before { content: '\e8a8'; } /* '' */
.icon-minimize-2:before { content: '\e8a9'; } /* '' */
.icon-minus:before { content: '\e8aa'; } /* '' */
.icon-minus-circle:before { content: '\e8ab'; } /* '' */
.icon-minus-square:before { content: '\e8ac'; } /* '' */
.icon-monitor:before { content: '\e8ad'; } /* '' */
.icon-moon:before { content: '\e8ae'; } /* '' */
.icon-more-horizontal:before { content: '\e8af'; } /* '' */
.icon-more-vertical:before { content: '\e8b0'; } /* '' */
.icon-mouse-pointer:before { content: '\e8b1'; } /* '' */
.icon-move:before { content: '\e8b2'; } /* '' */
.icon-music:before { content: '\e8b3'; } /* '' */
.icon-navigation:before { content: '\e8b4'; } /* '' */
.icon-navigation-2:before { content: '\e8b5'; } /* '' */
.icon-octagon:before { content: '\e8b6'; } /* '' */
.icon-package:before { content: '\e8b7'; } /* '' */
.icon-paperclip:before { content: '\e8b8'; } /* '' */
.icon-pause:before { content: '\e8b9'; } /* '' */
.icon-pause-circle:before { content: '\e8ba'; } /* '' */
.icon-pen-tool:before { content: '\e8bb'; } /* '' */
.icon-percent:before { content: '\e8bc'; } /* '' */
.icon-phone:before { content: '\e8bd'; } /* '' */
.icon-phone-call:before { content: '\e8be'; } /* '' */
.icon-phone-forwarded:before { content: '\e8bf'; } /* '' */
.icon-phone-incoming:before { content: '\e8c0'; } /* '' */
.icon-phone-missed:before { content: '\e8c1'; } /* '' */
.icon-phone-off:before { content: '\e8c2'; } /* '' */
.icon-phone-outgoing:before { content: '\e8c3'; } /* '' */
.icon-pie-chart:before { content: '\e8c4'; } /* '' */
.icon-play:before { content: '\e8c5'; } /* '' */
.icon-play-circle:before { content: '\e8c6'; } /* '' */
.icon-plus:before { content: '\e8c7'; } /* '' */
.icon-plus-circle:before { content: '\e8c8'; } /* '' */
.icon-plus-square:before { content: '\e8c9'; } /* '' */
.icon-pocket:before { content: '\e8ca'; } /* '' */
.icon-portal:before { content: '\e8cb'; } /* '' */
.icon-power:before { content: '\e8cc'; } /* '' */
.icon-printer:before { content: '\e8cd'; } /* '' */
.icon-publisher_ads:before { content: '\e8ce'; } /* '' */
.icon-radio:before { content: '\e8cf'; } /* '' */
.icon-refresh-ccw:before { content: '\e8d0'; } /* '' */
.icon-refresh-cw:before { content: '\e8d1'; } /* '' */
.icon-repeat:before { content: '\e8d2'; } /* '' */
.icon-rewind:before { content: '\e8d3'; } /* '' */
.icon-rotate-ccw:before { content: '\e8d4'; } /* '' */
.icon-rotate-cw:before { content: '\e8d5'; } /* '' */
.icon-rp:before { content: '\e8d6'; } /* '' */
.icon-rss:before { content: '\e8d7'; } /* '' */
.icon-save:before { content: '\e8d8'; } /* '' */
.icon-scissors:before { content: '\e8d9'; } /* '' */
.icon-search:before { content: '\e8da'; } /* '' */
.icon-send:before { content: '\e8db'; } /* '' */
.icon-server:before { content: '\e8dc'; } /* '' */
.icon-settings:before { content: '\e8dd'; } /* '' */
.icon-share:before { content: '\e8de'; } /* '' */
.icon-share-2:before { content: '\e8df'; } /* '' */
.icon-shield:before { content: '\e8e0'; } /* '' */
.icon-shield-off:before { content: '\e8e1'; } /* '' */
.icon-shopping-bag:before { content: '\e8e2'; } /* '' */
.icon-shopping-cart:before { content: '\e8e3'; } /* '' */
.icon-shuffle:before { content: '\e8e4'; } /* '' */
.icon-sidebar:before { content: '\e8e5'; } /* '' */
.icon-skip-back:before { content: '\e8e6'; } /* '' */
.icon-skip-forward:before { content: '\e8e7'; } /* '' */
.icon-slack:before { content: '\e8e8'; } /* '' */
.icon-slash:before { content: '\e8e9'; } /* '' */
.icon-sliders:before { content: '\e8ea'; } /* '' */
.icon-smartphone:before { content: '\e8eb'; } /* '' */
.icon-smile:before { content: '\e8ec'; } /* '' */
.icon-sort_ascending:before { content: '\e8ed'; } /* '' */
.icon-sort_descending:before { content: '\e8ee'; } /* '' */
.icon-speaker:before { content: '\e8ef'; } /* '' */
.icon-square:before { content: '\e8f0'; } /* '' */
.icon-star:before { content: '\e8f1'; } /* '' */
.icon-stop-circle:before { content: '\e8f2'; } /* '' */
.icon-sun:before { content: '\e8f3'; } /* '' */
.icon-sunrise:before { content: '\e8f4'; } /* '' */
.icon-sunset:before { content: '\e8f5'; } /* '' */
.icon-tablet:before { content: '\e8f6'; } /* '' */
.icon-tag:before { content: '\e8f7'; } /* '' */
.icon-target:before { content: '\e8f8'; } /* '' */
.icon-terminal:before { content: '\e8f9'; } /* '' */
.icon-thermometer:before { content: '\e8fa'; } /* '' */
.icon-thumbs-down:before { content: '\e8fb'; } /* '' */
.icon-thumbs-up:before { content: '\e8fc'; } /* '' */
.icon-toggle-left:before { content: '\e8fd'; } /* '' */
.icon-toggle-right:before { content: '\e8fe'; } /* '' */
.icon-tool:before { content: '\e8ff'; } /* '' */
.icon-trash:before { content: '\e900'; } /* '' */
.icon-trash-2:before { content: '\e901'; } /* '' */
.icon-trello:before { content: '\e902'; } /* '' */
.icon-trending-down:before { content: '\e903'; } /* '' */
.icon-trending-up:before { content: '\e904'; } /* '' */
.icon-triangle:before { content: '\e905'; } /* '' */
.icon-truck:before { content: '\e906'; } /* '' */
.icon-tv:before { content: '\e907'; } /* '' */
.icon-twitch:before { content: '\e908'; } /* '' */
.icon-twitter:before { content: '\e909'; } /* '' */
.icon-type:before { content: '\e90a'; } /* '' */
.icon-typing_cursor:before { content: '\e90b'; } /* '' */
.icon-umbrella:before { content: '\e90c'; } /* '' */
.icon-underline:before { content: '\e90d'; } /* '' */
.icon-unlock:before { content: '\e90e'; } /* '' */
.icon-upload:before { content: '\e90f'; } /* '' */
.icon-upload-cloud:before { content: '\e910'; } /* '' */
.icon-user:before { content: '\e911'; } /* '' */
.icon-user-check:before { content: '\e912'; } /* '' */
.icon-user-minus:before { content: '\e913'; } /* '' */
.icon-user-plus:before { content: '\e914'; } /* '' */
.icon-users:before { content: '\e915'; } /* '' */
.icon-user-x:before { content: '\e916'; } /* '' */
.icon-video:before { content: '\e917'; } /* '' */
.icon-video-off:before { content: '\e918'; } /* '' */
.icon-voicemail:before { content: '\e919'; } /* '' */
.icon-volume:before { content: '\e91a'; } /* '' */
.icon-volume-1:before { content: '\e91b'; } /* '' */
.icon-volume-2:before { content: '\e91c'; } /* '' */
.icon-volume-x:before { content: '\e91d'; } /* '' */
.icon-watch:before { content: '\e91e'; } /* '' */
.icon-wifi:before { content: '\e91f'; } /* '' */
.icon-wifi-off:before { content: '\e920'; } /* '' */
.icon-wind:before { content: '\e921'; } /* '' */
.icon-x:before { content: '\e922'; } /* '' */
.icon-x-circle:before { content: '\e923'; } /* '' */
.icon-x-octagon:before { content: '\e924'; } /* '' */
.icon-x-square:before { content: '\e925'; } /* '' */
.icon-youtube:before { content: '\e926'; } /* '' */
.icon-zap:before { content: '\e927'; } /* '' */
.icon-zap-off:before { content: '\e928'; } /* '' */
.icon-zoom-in:before { content: '\e929'; } /* '' */
.icon-zoom-out:before { content: '\e92a'; } /* '' */
